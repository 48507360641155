<template>
  <header class="header">
    <div class="inner">
      <div class="left">
        <button class="h_btn back" @click="fnClose"></button>
      </div>
      <h1 class="h1">
        <span>{{ year }}년 {{ month }}월 고지서</span>
      </h1>
    </div>
  </header>
  <nav class="nav" v-if="$Util.isEmpty(item.filePaths)">
    <div class="inner">
      <div class="btn_wrap">
        <button :class="{ on: tab === 0 }" @click="fnTab(0)">
          <span>납입 통지서</span>
        </button>
        <button :class="{ on: tab === 1 }" @click="fnTab(1)">
          <span>전월 관리비 사용</span>
        </button>
        <button :class="{ on: tab === 2 }" @click="fnTab(2)">
          <span>관리보고</span>
        </button>
      </div>
    </div>
  </nav>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con" v-if="!$Util.isEmpty(item.filePaths)">
          <div
            class="list_page"
            v-for="(data, index) in item.filePaths"
            :key="index"
          >
            <div class="bill_img_box">
              <img
                :src="$ConstCode.getImagePath(data.imagePath, 'origin/')"
                alt="img"
                @click="fnZoom(data.imagePath)"
              />
            </div>
          </div>
        </div>
        <div
          class="page_con page_bg"
          v-if="!$Util.isEmpty(item) && $Util.isEmpty(item.filePaths)"
        >
          <div class="bill_page">
            <h2 class="h2">
              {{ item.building.address }}<br />{{ item.building.name }}
            </h2>
            <!-- 납입통지서 -->
            <payment-notice v-if="tab === 0" :item="item" />
            <!-- 전월 관리비 사용 -->
            <manage-fee v-if="tab === 1" :item="item" />

            <!-- 관리보고 -->
            <manage-report v-if="tab === 2" :item="item" />

            <div class="bottom_main" v-if="tab !== 1">
              <div class="bottom_wrap2">
                <div class="left">
                  <span class="txt">납부장소: {{ item.building.address }}</span>
                  <span class="txt" style="width: 100%"
                    >계좌번호:
                    {{ $ConstCode.CODE_BANK_NAMES[item.building.bankNo] }}
                    {{ item.building.account }}</span
                  >
                  <span class="txt">예금주: {{ item.building.accName }}</span>
                  <span class="strong_txt">*입금시 호수 기재 요망</span>
                </div>
                <div class="right">
                  <span class="sign">취급자인</span>
                  <img src="@/assets/static/images/sign.svg" alt="sign" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import PaymentNotice from '@/components/views/bill/payment-notice'
import ManageFee from '@/components/views/bill/manage-fee'
import ManageReport from '@/components/views/bill/manage-report'

const layoutState = () => {
  const { getters, dispatch } = useStore()
  const routeLayout = computed(() => {
    return getters['layout/getRouteLayout']
  })
  const fnUpdateRouteLayout = ({ titleName = '' }) => {
    dispatch('layout/updateRouteLayout', {
      titleName: titleName
    })
  }
  return { routeLayout, fnUpdateRouteLayout }
}

export default {
  name: 'bill-view',
  components: {
    PaymentNotice,
    ManageFee,
    ManageReport
  },
  description: '고지서 상세 페이지',
  setup() {
    const { proxy } = getCurrentInstance()
    const route = useRoute()
    const router = useRouter()
    const { dispatch } = useStore()
    const query = route.query

    const state = reactive({
      tab: 0,
      item: {},
      year: '',
      month: '',
      name
    })

    const fnTab = val => {
      state.tab = val
    }
    const fnClose = () => {
      router.go(-1)
    }

    const fetchData = async () => {
      const res = await proxy.$BillSvc.fetchUserBill(query)
      if (res.code === 1) {
        state.item = res.entity
        state.year = res.entity.bill.year
        state.month = res.entity.bill.month
      }
    }

    const fnZoom = async path => {
      const payload = {}
      payload.component = proxy.$modalAlertNames.IMAGE
      payload.layout = {
        imagePath: path
      }
      payload.callBack = proxy => {
        proxy.$emit('close')
      }
      await dispatch('layout/pushModalAlertComponent', payload)
    }

    fetchData()

    return {
      ...toRefs(state),
      fnTab,
      ...layoutState(),
      fnClose,
      fnZoom
    }
  }
}
</script>

<style scoped></style>
