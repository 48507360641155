<template>
  <div class="box_shadow">
    <div class="box">
      <div class="box_ttl toggle" @click="fnShow(1)" :class="{ on: isShow }">
        <img src="@/assets/static/images/img_coin.svg" alt="icon" />
        <p>당월 부과 관리비 (미납포함)</p>
        <div class="icon"></div>
      </div>
      <div class="box_con toggle_box" v-show="isShow">
        <div
          class="box_list_wrap"
          v-for="(data, index) in item.detail"
          :key="index"
        >
          <div class="box_list">
            <div class="list_ttl toggle" @click="fnShow(2, index)">
              <div class="left" style="flex: 1;">
                <p class="refresh">{{ data.unit.unit }}</p>
              </div>
              <div class="middle_box">
                <div class="middle">
                  <p class="price refresh">
                    <span class="number">{{
                      $Util.isEmpty(data.bill.total)
                        ? 0
                        : $Util.formatNumber(data.bill.total)
                    }}</span
                    >원
                  </p>
                  <div
                    class="price_box"
                    :class="{ color_red: data.bill.isEmphasize === 1 }"
                  >
                    <span class="price_txt">미납: </span
                    ><span class="price_number"
                      >{{
                        data.bill.unpaidFee === 0
                          ? 0
                          : $Util.formatNumber(data.bill.unpaidFee)
                      }}원</span
                    >
                  </div>
                </div>
                <div class="right"></div>
              </div>
            </div>
          </div>
          <!--          <div class="toggle_box2" v-show="isShow">-->
          <div class="toggle_box2" style="display: none">
            <div class="box2_box">
              <div class="box2_txt">
                <span>관리비</span>
                <span class="txt number"
                  >{{
                    $Util.isEmpty(data.bill.manageFee)
                      ? 0
                      : $Util.formatNumber(data.bill.manageFee)
                  }}원</span
                >
              </div>
              <div class="box2_txt" v-show="item.building.isLongTerm === 1">
                <span>장충금</span>
                <span class="txt number"
                  >{{
                    $Util.isEmpty(data.bill.longTerm)
                      ? 0
                      : $Util.formatNumber(data.bill.longTerm)
                  }}원</span
                >
              </div>
              <div class="box2_txt" v-if="item.building.isWater === 1">
                <span>수도요금</span>
                <span class="txt number"
                  >{{
                    $Util.isEmpty(data.bill.waterFee)
                      ? 0
                      : $Util.formatNumber(data.bill.waterFee)
                  }}원</span
                >
              </div>
              <div class="box2_txt" v-if="item.building.isParking === 1">
                <span>주차</span>
                <span class="txt number"
                  >{{
                    $Util.isEmpty(data.bill.parkingFee)
                      ? 0
                      : $Util.formatNumber(data.bill.parkingFee)
                  }}원</span
                >
              </div>
              <div class="box2_txt" v-if="item.building.isGarbage === 1">
                <span>음식물 쓰레기</span>
                <span class="txt number"
                  >{{
                    $Util.isEmpty(data.bill.garbage)
                      ? 0
                      : $Util.formatNumber(data.bill.garbage)
                  }}원</span
                >
              </div>
              <div
                class="box2_txt"
                v-if="!$Util.isEmpty(item.bill.prorateName1)"
              >
                <span>{{ item.bill.prorateName1 }}</span>
                <span class="txt number"
                  >{{
                    $Util.isEmpty(data.bill.prorate1)
                      ? 0
                      : $Util.formatNumber(data.bill.prorate1)
                  }}원</span
                >
              </div>
              <div
                class="box2_txt"
                v-if="!$Util.isEmpty(item.bill.prorateName2)"
              >
                <span>{{ item.bill.prorateName2 }}</span>
                <span class="txt number"
                  >{{
                    $Util.isEmpty(data.bill.prorate2)
                      ? 0
                      : $Util.formatNumber(data.bill.prorate2)
                  }}원</span
                >
              </div>
              <div
                class="box2_txt"
                v-if="!$Util.isEmpty(item.bill.prorateName3)"
              >
                <span>{{ item.bill.prorateName3 }}</span>
                <span class="txt number"
                  >{{
                    $Util.isEmpty(data.bill.prorate3)
                      ? 0
                      : $Util.formatNumber(data.bill.prorate3)
                  }}원</span
                >
              </div>
              <div class="box2_txt" v-if="!$Util.isEmpty(item.building.text1)">
                <span>{{ item.building.text1 }}</span>
                <span class="txt"
                  >{{
                    $Util.isEmpty(data.bill.text1)
                      ? 0
                      : $Util.formatNumber(data.bill.text1)
                  }}원</span
                >
              </div>
              <div class="box2_txt" v-if="!$Util.isEmpty(item.building.text2)">
                <span>{{ item.building.text2 }}</span>
                <span class="txt"
                  >{{
                    $Util.isEmpty(data.bill.text2)
                      ? 0
                      : $Util.formatNumber(data.bill.text2)
                  }}원</span
                >
              </div>
              <div class="box2_txt" v-if="!$Util.isEmpty(data.bill.remark)">
                <span>비고</span>
                <span class="txt">{{ data.bill.remark }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="box_shadow m_12">
    <div class="box box_one">
      <div class="box_list_wrap">
        <div class="box_list">
          <div class="list_ttl">
            <div class="left no_width">
              <p>{{ item.totalUnit }}세대</p>
            </div>
            <div class="middle_box">
              <div class="middle _m_4">
                <p class="price">
                  <span class="number">{{
                    $Util.formatNumber(item.totalPaid)
                  }}</span
                  >원
                </p>
                <div class="price_box">
                  <span class="price_txt">미납: </span
                  ><span class="price_number"
                    >{{
                      item.totalUnpaid === 0
                        ? '0'
                        : $Util.formatNumber(item.totalUnpaid * -1)
                    }}원</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="box_shadow">
    <div class="box box_one orange">
      <div class="box_list_wrap">
        <div class="box_list">
          <div class="list_ttl">
            <div class="left">
              <p class="left_ttl" style="white-space: normal;">
                납부 기한: 매달 20일
              </p>
              <p class="left_con white_wrap">
                납부 기한이 경과하면 소정의 연체료를 징수하게 됩니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="box_shadow">
    <div class="box">
      <div class="box_ttl size18">
        <img src="@/assets/static/images/i_notice.svg" alt="icon" />
        <p>안내사항</p>
      </div>
      <div class="box_con">
        <div class="box_list_wrap wrap_notice">
          <div class="box_list m_8">
            <p class="notice_txt2">
              {{ item.bill.paymentNoti }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'

export default {
  name: 'component-bill-payment-notice',
  description: '고지서 > 납입 통지서',
  props: {
    item: {
      type: [Object, Array],
      default: () => {}
    }
  },
  setup() {
    const state = reactive({
      isShow: true
    })

    const fnShow = (type, index) => {
      if (type === 1) {
        state.isShow = !state.isShow
      } else {
        const box = document.getElementsByClassName('toggle_box2')[index]
        if (box.style.display === 'none') {
          box.style.display = 'block'
        } else {
          box.style.display = 'none'
        }
      }
    }

    return { ...toRefs(state), fnShow }
  }
}
</script>

<style scoped></style>
