<template>
  <div>
    <div class="box_shadow">
      <div class="box">
        <div class="box_ttl">
          <img src="@/assets/static/images/img_coin.svg" alt="icon" />
          <p>{{ item.beforeYear }}년 {{ item.beforeMonth }}월 분</p>
        </div>
        <div class="box_con toggle_box">
          <div
            class="box_list_wrap"
            v-for="(data, index) in item.fix"
            :key="index"
          >
            <div class="box_list">
              <div class="list_ttl">
                <div class="left">
                  <p class="txt">{{ data.expenses }}</p>
                </div>
                <div class="middle_box">
                  <div class="middle">
                    <p class="price refresh" v-if="data.amount > 0">
                      <span class="number">{{
                        $Util.formatNumber(data.amount)
                      }}</span
                      >원
                    </p>
                    <p class="price refresh" v-else>
                      이체 예정
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="toggle_box2"></div>
          </div>
          <div
            class="box_list_wrap"
            v-for="(data, index) in item.build"
            :key="index"
          >
            <div class="box_list">
              <div class="list_ttl">
                <div class="left">
                  <p class="txt">{{ data.expenses }}</p>
                </div>
                <div class="middle_box">
                  <div class="middle">
                    <p class="price refresh" v-if="data.amount > 0">
                      <span class="number">{{
                        $Util.formatNumber(data.amount)
                      }}</span
                      >원
                    </p>
                    <p class="price refresh" v-else>
                      이체 예정
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="toggle_box2"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="box_shadow m_12">
      <div class="box box_one">
        <div class="box_list_wrap">
          <div class="box_list">
            <div class="list_ttl">
              <div class="left">
                <p>관리비 지출 총 액</p>
              </div>
              <div class="middle_box">
                <div class="middle">
                  <p class="price refresh">
                    <span class="number">{{
                      $Util.formatNumber(item.surplus.manageFeeExp)
                    }}</span
                    >원
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box_shadow m_12">
      <div class="box box_one">
        <div class="box_list_wrap">
          <div class="box_list">
            <div class="list_ttl">
              <div class="left">
                <p>지난달 납부 관리비</p>
              </div>
              <div class="middle_box">
                <div class="middle">
                  <p class="price refresh">
                    <span class="number">{{
                      $Util.formatNumber(item.surplus.lastMonthFee)
                    }}</span
                    >원
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box_shadow m_12">
      <div class="box box_one">
        <div class="box_list_wrap">
          <div class="box_list">
            <div class="list_ttl">
              <div class="left">
                <p>전월 잉여금</p>
              </div>
              <div class="middle_box">
                <div class="middle">
                  <p class="price refresh">
                    <span class="number">{{
                      $Util.formatNumber(item.lastSurplusFee)
                    }}</span
                    >원
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box_shadow m_12">
      <div class="box box_one">
        <div class="box_list_wrap">
          <div class="box_list">
            <div class="list_ttl">
              <div class="left">
                <p>총 잉여금</p>
              </div>
              <div class="middle_box">
                <div class="middle">
                  <p class="price refresh">
                    <span class="number">{{
                      $Util.formatNumber(item.surplus.surplus)
                    }}</span
                    >원
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box_shadow m_12" v-if="item.building.isLongTerm === 1">
      <div class="box box_one">
        <div class="box_list_wrap">
          <div class="box_list">
            <div class="list_ttl">
              <div class="left">
                <p>장기수선충당금</p>
              </div>
              <div class="middle_box">
                <div class="middle">
                  <p class="price refresh">
                    <span class="number">{{
                      $Util.formatNumber(item.surplus.longTerm)
                    }}</span
                    >원
                  </p>
                  <div class="price_box">
                    <span class="price_txt">총 잉여금에 포함</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box_shadow m_12" v-if="item.building.isDefect === 1">
      <div class="box box_one">
        <div class="box_list_wrap">
          <div class="box_list">
            <div class="list_ttl">
              <div class="left">
                <p>하자보수금</p>
              </div>
              <div class="middle_box">
                <div class="middle">
                  <p class="price refresh">
                    <span class="number">{{
                      $Util.formatNumber(item.surplus.defect)
                    }}</span
                    >원
                  </p>
                  <div class="price_box">
                    <span class="price_txt">총 잉여금에 포함</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box_shadow">
      <div class="box">
        <div class="box_ttl size18">
          <img src="@/assets/static/images/i_notice.svg" alt="icon" />
          <p>안내사항</p>
        </div>
        <div class="box_con">
          <div class="box_list_wrap wrap_notice">
            <div class="box_list m_8">
              <p class="notice_txt2">
                {{ item.bill.manageFeeNoti }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'component-bill-manage-fee',
  description: '고지서 > 전월 관리비 사용',
  props: {
    item: {
      type: [Object, Array],
      default: () => {}
    }
  }
}
</script>

<style scoped></style>
