<template>
  <div class="box_shadow">
    <div class="box box_one">
      <div class="box_list_wrap">
        <div class="box_list type02">
          <div class="list_ttl">
            <div class="left flex_box">
              <img src="@/assets/static/images/i_user.svg" alt="icon" />
              <p>입금자 확인</p>
            </div>
            <div class="middle_box">
              <div class="middle">
                <p class="name">{{ item.bill.sender }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="round">
    <div class="round_wrap">
      <p>민원 통화가 어려울 시 <span>010-6880-9525</span>로 문자 주세요.</p>
    </div>
  </div>
  <div class="box_shadow">
    <div class="box">
      <div class="box_ttl size18">
        <img src="@/assets/static/images/i_notice.svg" alt="icon" />
        <p>안내사항</p>
      </div>
      <div class="box_con">
        <div class="box_list_wrap wrap_notice">
          <div class="box_list m_8">
            <p class="notice_txt2">
              {{ item.bill.compNoti }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="round">
    <div class="round_wrap">
      <p>궁금하신 문의는 <span>1800-9525</span>로 부탁드립니다.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'component-bill-manage-report',
  description: '고지서 > 관리보고',
  props: {
    item: {
      type: [Object, Array],
      default: () => {}
    }
  },
  setup() {}
}
</script>

<style scoped></style>
